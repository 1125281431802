"use strict";

import "../scss/main.scss";

async function getEndpoint(prefix) {
    let lastIndex = prefix.lastIndexOf('-');
    let pre = prefix.slice(0, lastIndex);
    let post = prefix.slice(lastIndex + 1)
    return `/api/v1/${pre}/${post}`
}

async function getData(endpoint, params = {}) {
    params["format"] = "rows";
    console.log("requesting data")
    let response = await fetch(`${endpoint}?${new URLSearchParams(params)}`);
    console.log("received chart data")
    let data = await response.json();
    console.log("deserialized data")
    console.log(data)
    return data;
}

const labels = {
    gas_day: "Gastag",
    gas_week: "Gaswoche",
    gas_monat: "Gasmonat",
    gas_quartal: "Gasquartal",
    gas_jahr: "Gasjahr",
    flow_norway: "Norwegen",
    flow_denmark: "Dänemark",
    flow_benelux: "Belux",
    flow_netherlands: "Niederlande",
    flow_france: "Frankreich",
    flow_switzerland: "Schweiz",
    flow_czechia: "Tschechien",
    flow_nordstream: "Nordstream",
    flow_austria: "Österreich",
    flow_poland: "Polen",
    import_norway: "Norwegen",
    import_denmark: "Dänemark",
    import_benelux: "Belux",
    import_netherlands: "Niederlande",
    import_france: "Frankreich",
    import_switzerland: "Schweiz",
    import_czechia: "Tschechien",
    import_nordstream: "Nordstream",
    import_austria: "Österreich",
    import_poland: "Polen",
    slp_consumption: "SLP-Verbrauch",
    rlm_consumption: "RLM-Verbrauch",
    gas_in_storage: "Speicherfüllstände",
    storage: "N/A",
    production: "N/A",
}


async function makeTable(prefix, tableData) {
    console.log(tableData)
    let tableDiv = $(`#${prefix}-table`);
    let columns = [];
    tableData.data.slice(0, 1)[0].forEach((element, index) => {
        let column = {
            title: labels[element]
        };
        if (index > 0) {
            column.render = DataTable.render.number( '.', ',', 4, '' )
        }
        columns.push(column)
    });
    let data = tableData.data.slice(1);
    let minDate = new DateTime($('#min-date-input'), {
        format: 'YYYY-MM-DD',
        minDate: new Date(data[0][0])
    });
    minDate.val(data[0][0])
    let maxDate = new DateTime($('#max-date-input'), {
        format: 'YYYY-MM-DD',
        maxDate: new Date(data[data.length - 1][0])
    });
    maxDate.val(data[data.length - 1][0])
    let table = tableDiv.DataTable(
        {
            data: data,
            columns: columns,
            deferRender: true,
            destroy: true,
            pagingType: "full_numbers",
            language: {
                searchPlaceholder: "Daten durchsuchen",
                search: "_INPUT_",
                paginate: {
                    first: "Erste",
                    previous: "Zurück",
                    next: "Nächste",
                    last: "Letzte",
                },
                thousands: ",",
                zeroRecords: "Keine passenden Einträge gefunden",
                emptyTable: "Keine Daten in der Tabelle vorhanden",
                info: "_START_ bis _END_ von _TOTAL_ Einträgen",
                infoEmpty: "Keine Daten vorhanden",
                infoFiltered: "(gefiltert von _MAX_ Einträgen)",
                lengthMenu: "_MENU_ Einträge anzeigen",
                loadingRecords: "Wird geladen ..",
                processing: "Bitte warten ..",
            },
            responsive: true,
            buttons: [
                {
                    extend: "csv",
                    filename: function () { return makeFilename();}
                },
                {
                    extend: "excel",
                    filename: function () { return makeFilename();}
                },
            ],
            lengthChange: true,
            dom: 'lrtip',
        }
    );
    // Add the download buttons
    table.buttons().container().addClass("hidden")
    .appendTo(tableDiv.parent().parent());
    return {
        table: table,
        minDate: minDate,
        maxDate: maxDate,
    }
}

function makeFilename() {
    let pre = $("h1")[0].innerText
    let resEl = document.getElementById("resolution");
	let res =  resEl.options[resEl.selectedIndex].text
    let min = document.getElementById("min-date-input").value
    let max = document.getElementById("max-date-input").value
    return `${pre} - ${res} - ${min} bis ${max}`
}

async function makeChart(prefix, chartData) {
    console.log(`Making plot for ${prefix}`)
    Highcharts.setOptions({
        lang: {
            decimalPoint: ',',
            thousandsSep: '.',
            loading: 'Daten werden geladen...',
            months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
            exportButtonTitle: "Exportieren",
            printButtonTitle: "Drucken",
            rangeSelectorFrom: "Von",
            rangeSelectorTo: "Bis",
            rangeSelectorZoom: "Zeitraum",
            downloadPNG: 'Download als PNG-Bild',
            downloadJPEG: 'Download als JPEG-Bild',
            downloadPDF: 'Download als PDF-Dokument',
            downloadSVG: 'Download als SVG-Bild',
            resetZoom: "Zoom zurücksetzen",
            resetZoomTitle: "Zoom zurücksetzen"
              }
    });
    let chartType;
    let yAxisMin;
    let yAxisMax;
    let data = chartData.data;

    if (prefix === "imports-timeseries") {
        chartType = "area"
        yAxisMin = 0
    } else if (prefix === "consumption-timeseries") {
        yAxisMin = 0;
        chartType = "line";
    } else {
        chartType = "line"
        yAxisMin = -1
    }
    let series = []
    console.log("Let's make the actual chart")
    let chart = Highcharts.stockChart(`${prefix}-plot`, {
        chart: {
            type: chartType
        },
        title: {
        },
        xAxis: {
            type: "datetime",
            labels: {
                formatter: function() {
                    console.log(this.value)
                return new Date(this.value).toLocaleDateString("de-DE");
                }
            },
            events: {
                afterSetExtremes(e) {
                    let event = new Event("dashboardMinMax", {bubbles: true})
                    event.min = e.min;
                    event.max = e.max;
                    document.body.dispatchEvent(event)
                    console.log(e)
                    console.log(e)
                }
            }

        },
        tooltip: {
            useHTML: true,
            headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
            pointFormatter: function () {
                return `<tr><td style="color: ${this.color}">${labels[this.series.name]}</td>` +
                `<td style="text-align: right"><b>${this.y.toLocaleString("de-DE", {minimumFractionDigits: 4})} TWh</b></td></tr>`;
            },
            footerFormat: '</table>',
            valueDecimals: 2,
            shared: true,
            split: false,
        },
        yAxis: {
            min: yAxisMin,
            max: yAxisMax,
            title: {
                text: "TWh"
            },
            opposite: false
        },
        rangeSelector: {
                buttons: [{
                    count: 1,
                    type: 'month',
                    text: '1M',
            }, {
                    count: 5,
                    type: 'month',
                    text: '5M'
            }, {
                    type: 'all',
                    text: 'Alles'
            }],
            inputEnabled: false,
            selected: 2,        
        },
        plotOptions: {
            area: {
                stacking: 'normal',
            }
        },
        credits :  {
            enabled : false
        },
        navigator :{
            enabled: true,
            series: series,
        },
        series: series,
        data: {rows: data},
        exporting: {
            enabled: false
        },
        legend: {
            enabled: true,
            labelFormatter: function () {
                return `${labels[this.name]}`;
            },
        }
    });
    console.log("The chart should be ready")
    return chart;
}


async function updateView(prefix, resolution, chart, table) {
    let params = {resolution: resolution};
    let endpoint = await getEndpoint(prefix);
    let dataset = await getData(endpoint, params);
    chart.update({
        data: {
            rows: dataset.data
        },
    });
    let tableDiv = $(`#${prefix}-table`);
    tableDiv.dataTable().fnClearTable();
    $(tableDiv.dataTable().api().column(0).header()).html(labels[dataset.data[0][0]]);
    tableDiv.dataTable().fnAddData(dataset.data);
}

async function makeView(prefix, params = {}) {
    console.log(`Making view for ${prefix}`)
    let endpoint = await getEndpoint(prefix);
    let data = await getData(endpoint, params)
    let chart = await makeChart(prefix, data);
    let tableObjects = await makeTable(prefix, data);
    $.fn.dataTable.ext.search.push(
        function( settings, data, dataIndex ) {
            console.log("called search")
            var min = tableObjects.minDate.val();
            var max = tableObjects.maxDate.val();
            var date = new Date( data[0] );
     
            if (
                ( min === null && max === null ) ||
                ( min === null && date <= max ) ||
                ( min <= date   && max === null ) ||
                ( min <= date   && date <= max )
            ) {
                return true;
            }
            return false;
        }
    );

    // Refilter the table and chart
    console.log(tableObjects.maxDate)
    $('#min-date-input').on('change', function (e) {
        tableObjects.table.draw();
        let min = Date.parse($('#min-date-input').val())
        let max = Date.parse($('#max-date-input').val())
        chart.xAxis[0].setExtremes(min, max)
    });

    $('#max-date-input').on('change', function (e) {
        tableObjects.table.draw();
        let min = Date.parse($('#min-date-input').val())
        let max = Date.parse($('#max-date-input').val())
        chart.xAxis[0].setExtremes(min, max)
    });

    $('#max-date-input').on('keyup change', function (e) {
        tableObjects.table.draw();
    });

    // Set up custom image download buttons
    ["jpeg", "png"].forEach(fileFormat => {
        $(`#${prefix}-download-${fileFormat}`).on('click', function (e) {
            chart.exportChart({
                type: `image/${fileFormat}`,
                filename: makeFilename()
            });
        });
    });

    // Set up custom tabular download buttons
    ["csv", "xlsx"].forEach((fileFormat, index) => {
        $(`#${prefix}-download-${fileFormat}`).on('click', function (e) {
            tableObjects.table.buttons(index).trigger();
        });
    })

    $('#resolution').on('change', function (e) {
        let resolution = $(e.target).val();
        updateView(prefix, resolution, chart, tableObjects.table);
    });

    document.addEventListener("dashboardMinMax", function(e) {
        let rangeHasChanged = false;
        let newMax = Highcharts.dateFormat('%Y-%m-%d', new Date(e.max))
        let oldMax = $('#max-date-input').val();
        if (newMax != oldMax) {
            $('#max-date-input').val(newMax);
            tableObjects.maxDate.val(newMax)
            rangeHasChanged = true;
        }
        let newMin = Highcharts.dateFormat('%Y-%m-%d', new Date(e.min))
        let oldMin = $('#min-date-input').val();
        console.log(`new min ${newMin}`)
        if (newMin != oldMin) {
            $('#min-date-input').val(newMin);
            tableObjects.minDate.val(newMin)
            console.log(tableObjects.minDate)
            rangeHasChanged = true;
        }
        console.log(`old: ${oldMin} new: ${newMin}`)
        console.log(e)
        if (rangeHasChanged) {
            console.log("range changed")
            tableObjects.table.search();
            tableObjects.table.draw()
            console.log("grgert")
        }
    });

    return tableObjects;
}

function makeViews() {
    document.addEventListener("DOMContentLoaded", function () {
        ["imports-timeseries", "flows-timeseries", "storage-timeseries", "consumption-timeseries"].forEach(prefix => {
            if ($(`#${prefix}-dataview`).length) {
                makeView(prefix)
            }
        });
    });
}

window["GasDashboard"] = {
    makeViews: makeViews,
};
